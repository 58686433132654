<template>
    <div>
        <router-view class="dm"></router-view>
    </div>
</template>
<script>
export default {
    name: "data_management",
    data() {
        return {

        }
    }
}
</script>
<style scoped>
.dm{
    width: 100%;
    height: 100%;
}
</style>
